angular.module('TV', [])
    .controller('TVController', ['$scope', '$routeParams', 'TVService', '$sce', function ($scope, $routeParams, TVService, $sce) {
        //let myviewer;
        TVService.getToken()
            .then(function (response) { });

        TVService.getSitio($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.sitio = data;
                $scope.sitioUrl = $sce.trustAsHtml($scope.sitio.sitio);
                });
    }]);