angular.module('inspecciones')
    .controller('damageController', ['$rootScope', '$scope', '$location', '$route', '$routeParams', 'inspeccionesService', 'authenticationInfo', 'authenticationService',
        function ($rootScope, $scope, $location, $route, $routeParams, inspeccionesService, authenticationInfo, authenticationService) {

            $scope.authentication = authenticationInfo;
            //$scope.InfraestructuraId = $routeParams.idInfraestructura;           


            $scope.InspeccionId = $routeParams.idInspeccion;

            $scope.pestanyamenuinspeccion = 'general';

            $scope.indice = 0;


            if ($scope.InspeccionId > 0) {
                inspeccionesService.getInspeccion($scope.InspeccionId)
                    .then(function (response) {
                        var data = response.data;
                        $scope.inspeccion = data;
                        $scope.inspeccion.Descripcion = $scope.inspeccion.Descripcion.toString();
                        $scope.inspeccion.IndiceTubo = $scope.inspeccion.IndiceTubo.toString();
                        //$scope.inspeccion.IdentificadorTubo = $scope.inspeccion.IdentificadorTubo.toString();
                        $scope.inspeccion.FechaInicio = $scope.inspeccion.FechaInicio.toString();
                        $scope.inspeccion.FechaFin = $scope.inspeccion.FechaFin.toString();
                        $scope.inspeccion.NumTramos = $scope.inspeccion.NumTramos.toString();
                        $scope.inspeccion.LongTramo = $scope.inspeccion.LongTramo.toString();
                        $scope.inspeccion.Inspector = $scope.inspeccion.Inspector.toString();

                        //if ($scope.inspeccion.UltimaEjecutada == "si" || $scope.inspeccion.UltimaEjecutada == "Si" || $scope.inspeccion.UltimaEjecutada == "SI") {
                        //    $scope.inspeccion.UltimaEjecutada = "1";
                        //} else {
                        //    $scope.inspeccion.UltimaEjecutada = "0";
                        //}

                        $scope.inspeccion.IdentificadorTubo = $scope.inspeccion.Tunel.IdentificadorTubo.toString();

                        inspeccionesService.getDaños($scope.inspeccion.Id)
                            .then(function (response) {
                                $scope.damages = response.data;
                            });

                        //inspeccionesService.getElementosInspeccionados($scope.inspeccionId)
                        //    .then(function (response) {
                        //        var data = response.data;
                        //        $scope.elementosinspeccionados = data;
                        //    });


                        //inspeccionesService.getElementosEvaluados($scope.inspeccionId)
                        //    .then(function (response) {
                        //        var data = response.data;
                        //        $scope.elementosevaluados = data;
                        //    });

                    })
            }


            //$scope.ElementosInspeccionadosCargar = function (pestanya) {
            //    $scope.pestanyavisible = pestanya;
            //    inspeccionesService.getElementosInspeccionados($scope.inspeccionId)
            //        .then(function (response) {
            //            var data = response.data;
            //            $scope.elementosinspeccionados = data;
            //        });
            //}

            //$scope.ElementosEvaluadosCargar = function (pestanya) {
            //    $scope.pestanyavisible = pestanya;
            //    inspeccionesService.getElementosEvaluados($scope.inspeccionId)
            //        .then(function (response) {
            //            var data = response.data;
            //            $scope.elementosinspeccionados = data;
            //        });
            //}




        $scope.verDamage = function (idDaño) {
            $location.url('/damages/' + idDaño);
        }

        $scope.eliminarDamage = function (idDaño) {
            $location.url('/damages/' + idDaño);
        }


        $scope.volver = function () {
            $location.path('/infraestructura/' + $rootScope.InfraestructuraId);
            $rootScope.inspeccionUrl = true;
        }

        //$scope.ElementosInspeccionadosCargar = function () {
        //    $scope.pestanyavisible = 'elementosinspeccionados';
        //}

        //$scope.ElementosEvaluadosCargar = function () {
        //    $scope.pestanyavisible = 'elementosevaluados';
        //    }


        $scope.guardar = function (mostrarAlerta) {
            const timeElapsed = Date.now();
            const today = new Date(timeElapsed);

            var inspeccionSQL = new Object();
            inspeccionSQL.Id = $scope.InspeccionId;
            inspeccionSQL.Descripcion = $scope.inspeccion.Descripcion;
            inspeccionSQL.IndiceTubo = $scope.inspeccion.IndiceTubo;
            inspeccionSQL.IdentificadorTubo = $scope.inspeccion.IdentificadorTubo;
            inspeccionSQL.FechaInicio = $scope.inspeccion.FechaInicio;
            inspeccionSQL.FechaFin = $scope.inspeccion.FechaFin;
            inspeccionSQL.NumTramos = $scope.inspeccion.NumTramos;
            inspeccionSQL.LongTramo = $scope.inspeccion.LongTramo;

            //if ($scope.inspeccion.UltimaEjecutada == "1") {
            //    inspeccionSQL.UltimaEjecutada = "SI";
            //} else {
            //    inspeccionSQL.UltimaEjecutada = "NO";
            //}

            //inspeccionSQL.UltimaEjecutada = $scope.inspeccion.UltimaEjecutada;

            inspeccionesService.putInspeccion(inspeccionSQL)
                .then(function (data) {
                    inspeccionesService.getInspeccion($scope.InspeccionId)
                        .then(function (response) {
                            var data = response.data;
                            $scope.inspeccion = data;
                            $scope.inspeccion.Descripcion = $scope.inspeccion.Descripcion.toString();
                            $scope.inspeccion.IndiceTubo = $scope.inspeccion.IndiceTubo.toString();
                            $scope.inspeccion.IdentificadorTubo = $scope.inspeccion.IdentificadorTubo.toString();
                            $scope.inspeccion.FechaInicio = $scope.inspeccion.FechaInicio.toString();
                            $scope.inspeccion.FechaFin = $scope.inspeccion.FechaFin.toString();
                            $scope.inspeccion.NumTramos = $scope.inspeccion.NumTramos.toString();
                            $scope.inspeccion.LongTramo = $scope.inspeccion.LongTramo.toString();
                            //$scope.inspeccion.UltimaEjecutada = $scope.inspeccion.UltimaEjecutada.toString();


                            //if ($scope.inspeccion.UltimaEjecutada == "si" || $scope.inspeccion.UltimaEjecutada == "Si" || $scope.inspeccion.UltimaEjecutada == "SI") {
                            //    $scope.inspeccion.UltimaEjecutada = "1";
                            //} else {
                            //    $scope.inspeccion.UltimaEjecutada = "0";
                            //}

                            alert('Datos guardados correctamente');
                        })
                })
        };

        $scope.logout = function () {
            //$rootScope.bodylayout = "login";
            return authenticationService.logout();
        }

        $scope.verCategorias = function () {
            $rootScope.inspeccionUrl = false;
            $rootScope.parteUrl = false;
            $location.path('/categorias');
        };
    }
]);