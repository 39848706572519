var env = {};

//(function (window) {
//    window.__env = window.__env || {};

//    window.__env.apiUrl = 'https://localhost:44387/';    
//}(this));

if (window) {
    //Object.assign(env, window.__env); // Esto no funciona en Cordova
    env = JSON.parse(JSON.stringify(window.__env));
};

angular.module('app', ['ngRoute', 'ui.bootstrap', 'angularLocalStorage', 'ngFileUpload', 'infraestructuras', 'home', 'auth', 'login', 'usuariosAdmin', 'TV', 'visormodelos', 'gestiondocumental', 'sensores', 'tourvirtual', 'partes', 'infraestructurasAdmin', 'tiposTajoAdmin', 'propietariosAdmin', 'municipiosAdmin', 'entornosAdmin', 'indiceInspeccionAdmin', 'categoriaAdmin', 'galibos', 'inspecciones', 'chatbox'])


    //.constant('serviceUrl', 'http://localhost:64171/')
    //.constant('serviceUrl', 'http://10.0.2.2:64171/') // Para Depuración Android
    //.constant('serviceUrl', 'http://ptfdesarrollo/vt4lrestapi/')
    //.constant('serviceUrl', 'http://37.187.152.56:8051/')


    .constant('__env', env)
    .config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {

        $routeProvider
            .when('/login',
                {
                    templateUrl: 'app/login/login.html',
                    title: 'Login'
                })
            .when('/home', {
                templateUrl: 'app/home/home.html',
                title: 'Inicio'
            })
            .when('/login2FA',
                {
                    templateUrl: 'app/login/ClaveDobleFactor.html',
                    title: 'Doble Autenticacion'
                })
            .when('/admin/usuarios/nuevo', {
                templateUrl: 'app/admin/usuarios/nuevoUsuario.html',
                title: 'Nuevo Usuario'
            })
            .when('/admin/usuarios', {
                templateUrl: 'app/admin/usuarios/usuariosAdmin.html',
                title: 'Gestión Usuarios'
            })
            .when('/admin/editar/:id', {
                templateUrl: 'app/admin/usuarios/editarUsuario.html',
                title: 'Gestión Usuarios'
            })
            .when('/admin/usuario/:usuario', {
                templateUrl: 'app/admin/usuarios/editarUsuarioPropio.html',
                title: 'Gestión Usuario'
            })
            .when('/admin/infraestructuras/nueva', {
                templateUrl: 'app/admin/infraestructuras/nuevaInfraestructura.html',
                title: 'Nueva Infraestructura'
            })
            .when('/admin/infraestructurasAdmin', {
                templateUrl: 'app/admin/infraestructuras/infraestructurasAdmin.html',
                title: 'Gestión Infraestructuras'
            })
            .when('/admin/editarInf/:id', {
                templateUrl: 'app/admin/infraestructuras/editarInfraestructura.html',
                title: 'Gestión Infraestructuras'
            })
            .when('/admin/tipoTajo/nuevo', {
                templateUrl: 'app/admin/TipoTajo/nuevoTipoTajo.html',
                title: 'Nueva Tipo de Tajo'
            })
            .when('/admin/tiposTajoAdmin', {
                templateUrl: 'app/admin/TipoTajo/tiposTajoAdmin.html',
                title: 'Gestión Tipos de Tajo'
            })
            .when('/admin/editarTipoTajo/:id', {
                templateUrl: 'app/admin/TipoTajo/editarTipoTajo.html',
                title: 'Gestión Tipos de Tajo'
            })
            .when('/admin/Propietarios/nuevo', {
                templateUrl: 'app/admin/Propietarios/nuevoPropietario.html',
                title: 'Nuevo Propietario'
            })
            .when('/admin/propietariosAdmin', {
                templateUrl: 'app/admin/Propietarios/propietariosAdmin.html',
                title: 'Gestión Propietarios'
            })
            .when('/admin/editarPropietarios/:id', {
                templateUrl: 'app/admin/Propietarios/editarPropietario.html',
                title: 'Gestión Propietarios'
            })
            .when('/admin/municipios/nuevo', {
                templateUrl: 'app/admin/Municipios/nuevoMunicipio.html',
                title: 'Nuevo Municipio'
            })
            .when('/admin/municipiosAdmin', {
                templateUrl: 'app/admin/Municipios/municipiosAdmin.html',
                title: 'Gestión Municipios'
            })
            .when('/admin/editarMunicipios/:id', {
                templateUrl: 'app/admin/Municipios/editarMunicipio.html',
                title: 'Gestión Municipios'
            })
            .when('/admin/entornos/nuevo', {
                templateUrl: 'app/admin/Entornos/nuevoEntorno.html',
                title: 'Nuevo Entorno'
            })
            .when('/admin/entornosAdmin', {
                templateUrl: 'app/admin/Entornos/entornosAdmin.html',
                title: 'Gestión Entornos'
            })
            .when('/admin/editarEntornos/:id', {
                templateUrl: 'app/admin/Entornos/editarEntorno.html',
                title: 'Gestión Entornos'
            })
            .when('/admin/indiceInspeccion/nuevo', {
                templateUrl: 'app/admin/IndiceInspeccion/nuevoIndiceInspeccion.html',
                title: 'Nuevo Entorno'
            })
            .when('/admin/indiceInspeccionAdmin', {
                templateUrl: 'app/admin/IndiceInspeccion/indiceInspeccionAdmin.html',
                title: 'Gestión Entornos'
            })
            .when('/admin/editarIndiceInspeccion/:id', {
                templateUrl: 'app/admin/IndiceInspeccion/editarIndiceInspeccion.html',
                title: 'Gestión Entornos'
            })
            .when('/admin/categoria/nueva', {
                templateUrl: 'app/admin/Categorias/nuevaCategoria.html',
                title: 'Nueva Categoria'
            })
            .when('/admin/categoriaAdmin', {
                templateUrl: 'app/admin/Categorias/categoriaAdmin.html',
                title: 'Gestión Categorias'
            })
            .when('/admin/editarCategoria/:id', {
                templateUrl: 'app/admin/Categorias/editarCategoria.html',
                title: 'Gestión Categorias'
            })
            .when('/categorias', {
                templateUrl: 'app/infraestructuras/categoriasInfraestructuras.html',
                title: 'Infraestructuras'
            })
            .when('/infraestructuras/:id', {
                templateUrl: 'app/infraestructuras/infraestructuras.html',
                title: 'Infraestructuras'
            })
            .when('/infraestructura/:id', {
                templateUrl: 'app/infraestructuras/infraestructura.html',
                title: 'Infraestructura'
            })

            .when('/partes/:id', {
                templateUrl: 'app/partes/partes.html',
                title: 'Partes'
            })

            //.when('/inspecciones/:id', {
            //    templateUrl: 'app/inspecciones/inspecciones.html',
            //    title: 'Inspecciones'
            //})

            .when('/partes/:idInfraestructura/:id', {
                templateUrl: 'app/partes/parte.html',
                title: 'Parte'
            })

            .when('/inspecciones/:id', {
                templateUrl: 'app/inspecciones/inspeccion.html',
                title: 'Inspección'
            })

            .when('/damages/:idInspeccion/:id', {
                templateUrl: 'app/inspecciones/damage.html',
                title: 'Daño'
            })


            .when('/TV/:id', {
                templateUrl: 'app/TV/TV.html',
                title: 'TV'
            })
            .when('/modelo/:id', {
                templateUrl: 'app/VisorModelos/visormodelos.html',
                title: 'Modelos'
            })

            .when('/docs/:id', {
                templateUrl: 'app/GestionDocumental/GestionDocumental.html',
                title: 'Gestion Documental'
            })

            .when('/galibos/:id', {
                templateUrl: 'app/galibos/galibos.html',
                title: 'Cálculo de Galibos'
            })

            .otherwise({ redirectTo: "/home" });

    }])


    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor')
    }])


    .filter('filtroInspecciones', function ($filter) {
        return function (items, inspeccionBuscar) {
            if (!inspeccionBuscar)
                return items;

            // Filtro estándar
            var filtrados = $filter('filter')(items, inspeccionBuscar);

            // Reordenar según estado
            //filtrados.sort(comparadorExpedientesPorEstado(expedienteBuscar.Estado));

            return filtrados;
        };
    })

    .filter('filtroDaños', function ($filter) {
        return function (items, dañoBuscar) {
            if (!dañoBuscar)
                return items;

            // Filtro estándar
            var filtrados = $filter('filter')(items, dañoBuscar);

            // Reordenar según estado
            //filtrados.sort(comparadorExpedientesPorEstado(expedienteBuscar.Estado));

            return filtrados;
        };
    })



    .run(['$rootScope', '$location', '$route', 'authenticationService', 'authEvents', '$http',
        function ($rootScope, $location, $route, authenticationService, authEvents, $http) {

            // authenticationService.fillAuthData();

            $rootScope.$on(authEvents.login, function () {
                $location.path('/categorias');
            });

            $rootScope.$on(authEvents.logout, function () {
                $location.path('/home');
            });

            document.addEventListener('deviceready', onDeviceReady, false);
            function onDeviceReady() {
                navigator.splashscreen.hide();
                document.addEventListener('backbutton', function (e) {

                    if (($location.path() == '/home' || $location.path() == '/infraestructuras')
                        && confirm('¿Desea salir de la aplicación?')) {
                        e.preventDefault();
                        navigator.app.exitApp();
                    }
                    else {
                        navigator.app.backHistory();
                    }
                }, false);
            }
        }]);
