var infraestructuras = angular.module('chatbox');

infraestructuras.factory('chatboxService', ['$http', '__env', function infraestructurasService($http, __env) {

    var serviceUrl = __env.apiUrl;
    var chatboxUrl = 'ChatBox';

    function getChatBox(pregunta) {
        return $http({
            method: 'post',
            url: serviceUrl + chatboxUrl,
            params: {
                'request': pregunta
            }
        });
    }
    return {

        getChatBox: getChatBox
    }

}]);

