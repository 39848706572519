
var editarInspector = angular.module('inspectoresAdmin');

editarInspector.controller('inspectoresAdminController', ['$scope', '$routeParams', '$location', 'inspectoresAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, inspectoresAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;

    inspectoresAdminService.getInspector($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.inspector = data;
                $scope.descripcion = data.Descripcion;
            });

    $scope.gestionInspectores = function () {
        $location.path("/admin/inspectoresAdmin");
    }

    $scope.editarInspector = function () {
        inspectoresAdminController.put($scope.descripcion, $scope.inspector.Id).then(function (response) {
                var result = response.data;
                alert(result);
                //$location.path("/admin/usuarios");
            $location.path("/admin/inspectoresAdmin");

            }).catch(function (response) {
                $scope.error = inspectoresAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }


    }]);