angular.module('inspecciones')
    .controller('inspeccionController', ['$rootScope', '$scope', '$location', '$route', '$routeParams', 'inspeccionesService', 'TVService', 'infraestructurasService', 'infraestructurasAdminService', 'authenticationInfo', 'authenticationService', '$sce',
        function ($rootScope, $scope, $location, $route, $routeParams, inspeccionesService, TVService, infraestructurasService, infraestructurasAdminService, authenticationInfo, authenticationService, $sce) {

            $scope.authentication = authenticationInfo;
            //$scope.InfraestructuraId = $routeParams.idInfraestructura;

            $scope.InspeccionId = $routeParams.id;

            $scope.pestanyamenuinspeccion = 'general';
            //$scope.pestanyavisible = ($scope.InspeccionId > 0) ? 'general' : 'inspeccion';


            inspeccionesService.getTunelId($rootScope.InfraestructuraId)
                .then(function (response) {
                    $scope.TunelId = response.data;
                });


            infraestructurasAdminService.getPropietarios()
                .then(function (response) {
                    var data = response.data;
                    $scope.propietarios = data;
                });

            infraestructurasAdminService.getInspectores()
                .then(function (response) {
                    var data = response.data;
                    $scope.inspectores = data;
                });


            $scope.indice = 0;

            if ($scope.InspeccionId > 0) {
                inspeccionesService.getInspeccion($scope.InspeccionId)
                    .then(function (response) {
                        var data = response.data;
                        $scope.inspeccion = data;
                        $scope.inspeccion.Descripcion = $scope.inspeccion.Descripcion.toString();
                        $scope.inspeccion.IndiceTubo = $scope.inspeccion.IndiceTubo.toString();                        
                        $scope.inspeccion.FechaInicio = $scope.inspeccion.FechaInicio.toString();
                        $scope.inspeccion.FechaFin = $scope.inspeccion.FechaFin.toString();
                        $scope.inspeccion.FechaInspAnterior = $scope.inspeccion.FechaInspAnterior.toString();
                        $scope.inspeccion.NumTramos = $scope.inspeccion.NumTramos.toString();
                        $scope.inspeccion.LongTramo = $scope.inspeccion.LongTramo.toString();
                        $scope.inspeccion.Inspector = $scope.inspeccion.Inspector.toString();
                        $scope.inspeccion.Propietario = $scope.inspeccion.Propietario.toString();
                        $scope.inspeccion.InspectorId = $scope.inspeccion.InspectorId.toString();
                        $scope.inspeccion.PropietarioId = $scope.inspeccion.PropietarioId.toString();
                       // $scope.inspeccion.TunelId = $scope.TunelId.toString();

                        $scope.inspeccion.IdentificadorTubo = $scope.inspeccion.Tunel.IdentificadorTubo.toString();

                        inspeccionesService.getDaños($scope.inspeccion.Id)
                            .then(function (response) {
                                $scope.damages = response.data;
                            });

                        inspeccionesService.getDañosPorPK($scope.inspeccion.Id)
                            .then(function (response) {
                                $scope.damagesporpk = response.data;
                            });

                        inspeccionesService.getDañosPorTipo($scope.inspeccion.Id)
                            .then(function (response) {
                                $scope.damagesportipo = response.data;
                            });

                        inspeccionesService.getDañosPorElemento($scope.inspeccion.Id)
                            .then(function (response) {
                                $scope.damagesporelemento = response.data;
                            });

                    })
            }


            TVService.getSitio($rootScope.InfraestructuraId)
                .then(function (response) {
                    var data = response.data;
                    $scope.sitio = data;
                    $scope.sitioUrl = $sce.trustAsHtml("<iframe src='https://nube.infraestructuradigital.es/views/EntornoPruebasv2.html' style='width: 100%; height: 800px'></iframe>");
                });



            $scope.verDamage = function (idDaño) {
                $location.url('/damages/' + $scope.InspeccionId + '/' + idDaño);
            }


            $scope.validarSeleccion = function () {
                if (!$scope.informeSeleccionado) {
                    // Si no se selecciona un informe, muestra una alerta
                    alert("Por favor, seleccione un informe antes de continuar.");
                    return;
                }

                // Si se seleccionó un informe, procede con la generación
                $scope.imprimirInforme($scope.ultimainspeccion);
            };


            $scope.imprimirInforme = function () {
                infraestructurasService.getInforme($scope.ultimainspeccion.Id)
                    .then(function (data, status, headers) {

                        var filename = "Informe Inspeccion.doc";
                        var contentType = 'application/msword';

                        var blob = new Blob([data.data],
                            { type: contentType });

                        if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                            window.navigator.msSaveBlob(blob, filename);
                        else //Resto
                        {
                            var downloadLink = document.createElement("a");
                            var url = window.URL.createObjectURL(blob);
                            downloadLink.setAttribute("target", "_blank");
                            downloadLink.setAttribute("href", url);
                            downloadLink.setAttribute("download", filename);
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                            elemento.style.display = 'none';
                        }
                    })
            }


            $scope.eliminarDamage = function (idDaño) {                
                check = confirm("¿Seguro que deseas eliminar este daño?")

                if (check) {
                    inspeccionesService.eliminarDamage(idDaño)
                        .then(function (response) {
                            inspeccionesService.getDaños($scope.InspeccionId)
                                .then(function (response) {
                                    var data = response.data;
                                    $scope.damages = data;
                                });
                        });
                }
            };


            $scope.volver = function () {
                $location.path('/infraestructura/' + $rootScope.InfraestructuraId);
                $rootScope.inspeccionUrl = true;
            }


            $scope.guardar = function (mostrarAlerta) {
                const timeElapsed = Date.now();
                const today = new Date(timeElapsed);

                var inspeccionSQL = new Object();
                inspeccionSQL.Id = $scope.InspeccionId;
                inspeccionSQL.Descripcion = $scope.inspeccion.Descripcion;
                inspeccionSQL.IndiceTubo = $scope.inspeccion.IndiceTubo;
                inspeccionSQL.IdentificadorTubo = $scope.inspeccion.IdentificadorTubo;
                inspeccionSQL.FechaInicio = $scope.inspeccion.FechaInicio;
                inspeccionSQL.FechaFin = $scope.inspeccion.FechaFin;
                inspeccionSQL.NumTramos = $scope.inspeccion.NumTramos;
                inspeccionSQL.LongTramo = $scope.inspeccion.LongTramo;
                inspeccionSQL.InspectorId = $scope.inspeccion.InspectorId;
                inspeccionSQL.PropietarioId = $scope.inspeccion.PropietarioId;
                inspeccionSQL.TunelId = $scope.TunelId;





                // Validar que todos los valores sean numéricos
                if (!isNaN($scope.inspeccion.IndiceTubo) &&
                    !isNaN($scope.inspeccion.NumTramos) &&
                    !isNaN($scope.inspeccion.LongTramo))
                {
                    if (!$scope.inspeccion.FechaInicio || !$scope.inspeccion.FechaFin) {
                        alert("Debe introducir la Fecha de Inicio y la Fecha de Fin de la inspección.");
                        return; // Salir si las fechas no son válidas
                    }

                    // Convertir a números y luego a cadena
                    $scope.inspeccion.IndiceTubo = parseInt($scope.inspeccion.IndiceTubo, 10).toString();
                    $scope.inspeccion.NumTramos = parseInt($scope.inspeccion.NumTramos, 10).toString();
                    $scope.inspeccion.LongTramo = parseFloat($scope.inspeccion.LongTramo).toString(); // Usar parseFloat para valores decimales


                    inspeccionesService.putInspeccion(inspeccionSQL)
                        .then(function (data) {

                            if ($scope.InspeccionId == 0) {
                                $scope.InspeccionId = data.data ;
                            }
                            else {
                                $scope.InspeccionId = $scope.InspeccionId;
                            }


                            inspeccionesService.getInspeccion($scope.InspeccionId)
                                .then(function (response) {
                                    var data = response.data;
                                    $scope.inspeccion = data;
                                    $scope.inspeccion.Descripcion = $scope.inspeccion.Descripcion.toString();
                                    $scope.inspeccion.IndiceTubo = $scope.inspeccion.IndiceTubo.toString();
                                    $scope.inspeccion.IdentificadorTubo = $scope.inspeccion.IdentificadorTubo.toString();
                                    $scope.inspeccion.FechaInicio = $scope.inspeccion.FechaInicio.toString();
                                    $scope.inspeccion.FechaFin = $scope.inspeccion.FechaFin.toString();
                                    $scope.inspeccion.NumTramos = $scope.inspeccion.NumTramos.toString();
                                    $scope.inspeccion.LongTramo = $scope.inspeccion.LongTramo.toString();
                                    $scope.inspeccion.Inspector = $scope.inspeccion.Inspector.toString();
                                    $scope.inspeccion.Propietario = $scope.inspeccion.Propietario.toString();
                                    $scope.inspeccion.TunelId = $scope.TunelId.toString();
                                    $scope.inspeccion.InspectorId = $scope.inspeccion.InspectorId.toString();
                                    $scope.inspeccion.PropietarioId = $scope.inspeccion.PropietarioId.toString();

                                    alert('Datos guardados correctamente');
                                })
                        })

                } else {
                    alert("Compruebe que los valores Indice Tubo, Numero de Tramos y Longitud de Tramo sean numéricos");
                    // Puedes manejar el error según tus necesidades, como mostrar un mensaje al usuario
                }

            };

            $scope.logout = function () {                
                return authenticationService.logout();
            }

            $scope.verCategorias = function () {
                $rootScope.inspeccionUrl = false;
                $rootScope.parteUrl = false;
                $location.path('/categorias');
            };
        }
    ]);