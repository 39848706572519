var nuevoInspector = angular.module('inspectoresAdmin');

nuevoInspector.controller('nuevoInspectorAdminController', ['$scope', '$routeParams', '$location', 'inspectoresAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, inspectoresAdminService, authenticationInfo, authenticationService) {
    $scope.error = '';
    $scope.authentication = authenticationInfo;

    $scope.gestionInspectores = function () {
        $location.path("/admin/inspectoresAdmin");
    }

        $scope.guardarInspector = function () {
            inspectoresAdminService.post($scope.descripcion).then(function (response) {
                var result = response.data;
                alert(result);
                $location.path("/admin/inspectoresAdmin");
                //$location.path("/Admin");
            }).catch(function (response) {
                $scope.error = inspectoresAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    }]);