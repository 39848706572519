var inspectoresAdminService = angular.module('inspectoresAdmin');

inspectoresAdminService.factory('inspectoresAdminService', ['$http', '$q', '__env', function inspectoresAdminService($http, $q, __env) {
        var serviceUrl = __env.apiUrl;
        var inspectoresUrl = 'inspectores';
        

        function getInspectores() {
            return $http.get(serviceUrl + inspectoresUrl + '/Inspectores');
        }

        function getInspector(id) {
            return $http.get(serviceUrl + inspectoresUrl + '/Inspector/' + id);
        }

        function put(descripcion, id) {
            return $http({
                method: 'put',
                url: serviceUrl + inspectoresUrl + '/editarInspector/' + id,
                params:
                {
                    'descripcion': descripcion
                }
            });
        }

    function post(inspector) {
            //return $http.post(serviceUrl + usuariosUrl + '/registrar/' + idRolUsuario, usuario);
            return $http({
                method: 'post',
                url: serviceUrl + inspectoresUrl + '/registrarInspectores',
                params:
                {
                    'inspector': inspector
                }

            });
        }

        function obtenerPrimerErrorModelState(response) {
            var errores = [];
            for (var key in response.ModelState) {
                for (var i = 0; i < response.ModelState[key].length; i++) {
                    errores.push(response.ModelState[key][i]);
                }
            }
            return errores[0];
        }
        function eliminarInspectores(usuariosEliminar) {
            return $http.post(serviceUrl + usuariosUrl + '/eliminarInspectores/' + inspectoresEliminar, { headers: { 'Content-Type': 'application/json' } }).catch(function (response) {
                alert('Error');
            });
        }



        return {
            getInspectores: getInspectores,
            getInspector: getInspector,
            put: put,
            post: post,
            obtenerPrimerErrorModelState: obtenerPrimerErrorModelState,
            eliminarInspectores: eliminarInspectores,
        }

    }]);