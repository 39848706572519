var inspectoresAdmin = angular.module('inspectoresAdmin', []);

inspectoresAdmin.controller('inspectoresController', ['$scope', '$location', 'inspectoresAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $location, inspectoresAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;
    $scope.inspectoresSeleccionados = "";

    inspectoresAdminService.getInspectores()
            .then(function (response) {
                var data = response.data;
                $scope.inspectores = data;
            });

    $scope.verInspector = function (idInspector) {
        $location.path('/admin/editarInspectores/' + idInspector);
            //$location.path('/Admin/' + idUsuario);

        };

    $scope.crearInspector = function () {
        $location.path('/admin/Inspectores/nuevo');
    }

    $scope.inspectoresSeleccionados = ""
    $scope.eliminarInspectores = function () {
        var confirmado = confirm('¿Desea eliminar los inspectores seleccionados?')
        if (confirmado) {
            angular.forEach($scope.inspectores, function (inspector) {
                if (inspector.Selected) {
                    if ($scope.inspectoresSeleccionados != "") {
                        $scope.inspectoresSeleccionados = $scope.inspectoresSeleccionados + "," + inspector.Id
                    } else {
                        $scope.inspectoresSeleccionados = inspector.Id
                    }

                };
            });
            inspectoresAdminService.eliminarInspectores($scope.inspectoresSeleccionados).then(function (response) {
                var result = response.data;
                alert(result);
                $scope.inspectoresSeleccionados = [];
                inspectoresAdminService.getInspectores()
                    .then(function (response) {
                        var data = response.data;
                        $scope.inspectores = data;
                    });
            }).catch(function (response) {
                $scope.error = inspectoresAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }
    };

        $scope.seleccionarTodos = function () {
            if (!$scope.SeleccionadosTodos) {
                $scope.SeleccionadosTodos = true;
            } else {
                $scope.SeleccionadosTodos = false;
            }
            angular.forEach($scope.inspectores, function (inspector) {
                inspector.Selected = $scope.SeleccionadosTodos;
            });

        };

    }]);