var infraestructura = angular.module('infraestructuras');

infraestructura.controller('infraestructuraController', ['$rootScope', '$scope', '$location', 'infraestructurasService', '$routeParams', 'authenticationInfo', 'authenticationService', 'infraestructurasAdminService', 'inspeccionesService'
    , function ($rootScope, $scope, $location, infraestructurasService, $routeParams, authenticationInfo, authenticationService, infraestructurasAdminService, inspeccionesService) {

        $scope.authentication = authenticationInfo;
        $scope.pestanyavisible = 'general';
        $scope.pestanyamenuinspeccion = 'general';
        $scope.pestanyamenusuperior = 'informacion';
        $rootScope.InfraestructuraId = $routeParams.id;


        if ($rootScope.parteUrl == true) {
            $scope.OpcionSeleccionada = "app/Partes/partes.html";
            $scope.OpcionSeleccionadaClass = 'parte';
            $scope.pestanyaparte = "tab-parte active"
            $scope.pestanyanube = "tab-nube"
            $rootScope.parteUrl = false;
            $rootScope.inspeccionUrl = false;
        }
        else {
            if ($rootScope.inspeccionUrl == true) {
                $scope.pestanyamenusuperior = 'inspeccion';
            }
            $scope.OpcionSeleccionada = "app/TV/TV.html";
            $scope.OpcionSeleccionadaClass = 'tv';
            $scope.pestanyaparte = "tab-parte"
            $scope.pestanyanube = "tab-nube active"
            $rootScope.inspeccionUrl = false;
            $rootScope.parteUrl = false;
        }

        $scope.idObra = $routeParams.id;

        infraestructurasAdminService.getEntornos()
            .then(function (response) {
                var data = response.data;
                $scope.entornos = data;
            });


        infraestructurasAdminService.getPropietarios()
            .then(function (response) {
                var data = response.data;
                $scope.propietarios = data;
            });

        infraestructurasAdminService.getMunicipios()
            .then(function (response) {
                var data = response.data;
                $scope.municipios = data;
            });


        $scope.validarSeleccion = function () {
            if (!$scope.informeSeleccionado) {
                // Si no se selecciona un informe, muestra una alerta
                alert("Por favor, seleccione un informe antes de continuar.");
                return;
            }

            // Si se seleccionó un informe, procede con la generación
            $scope.imprimirInforme($scope.ultimainspeccion);
        };


        $scope.verInspeccion = function (idInspeccion) {
            $location.url('/inspecciones/' + idInspeccion);
        }

        // Variable que representa el valor del índice
        const indiceValor = 2; // Cambia este valor dinámicamente (1, 2 o 3)

        infraestructurasService.getInfraestructura($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.infraestructura = data;

                indicevalor = $scope.infraestructura.IndiceInspeccionId;
                if ($scope.infraestructura.CategoriaId == 4) {
                    $scope.pestanyavisible = 'visualizacion';
                    $scope.pestanyamenusuperior = 'visualizacion';
                }

                //const indiceTuboSpan = document.getElementById('indiceTubo');
                /*const indiceEstado = document.getElementById('indiceEstado');*/
                const indiceValorSpan = document.getElementById('indiceValor');
                const IndiceValorSpan = document.getElementById('Indice');
                

                if ($scope.infraestructura.IndiceInspeccion.Color == "Verde") {
                    $scope.infraestructura.IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-0 row";
                    $scope.infraestructura.IndiceEstadoInfraestructura = "indice-verde";
                    /*indiceEstado.style.color = "green";*/
                    indiceValorSpan.style.color = "green";
                    IndiceValorSpan.style.color = "green";
                    //indiceTuboSpan.style.color = "green";
                }
                else if ($scope.infraestructura.IndiceInspeccion.Color == "Amarillo") {
                    $scope.infraestructura.IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-1 row";
                    $scope.infraestructura.IndiceEstadoInfraestructura = "indice-naranja";
                    /*indiceEstado.style.color = "orange";*/
                    indiceValorSpan.style.color = "#FFEA00";
                    IndiceValorSpan.style.color = "#FFEA00";
                    //indiceValorSpan.style.fontFamily = "Arial, sans - serif";
                    //IndiceValorSpan.style.fontFamily = "Arial, sans - serif";
//                    indiceValorSpan.style.backgroundColor = "black";
                    //indiceTuboSpan.style.color = "yellow";
                }
                else if ($scope.infraestructura.IndiceInspeccion.Color == "Naranja") {
                    $scope.infraestructura.IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-1 row";
                    $scope.infraestructura.IndiceEstadoInfraestructura = "indice-naranja";
                    /*indiceEstado.style.color = "orange";*/
                    indiceValorSpan.style.color = "darkorange";
                    IndiceValorSpan.style.color = "darkorange";
                    //indiceTuboSpan.style.color = "orange";
                }
                else if ($scope.infraestructura.IndiceInspeccion.Color == "Rojo") {
                    $scope.infraestructura.IndiceInspeccionInfraestructura = "infraestructura-box infraestructura-status-2 row";
                    $scope.infraestructura.IndiceEstadoInfraestructura = "indice-rojo";
                    /*indiceEstado.style.color = "red";*/
                    indiceValorSpan.style.color = "red";
                    IndiceValorSpan.style.color = "red";
                    //indiceTuboSpan.style.color = "red";
                }


                infraestructurasService.getTunel($scope.infraestructura.Id)
                    .then(function (response) {
                        var data = response.data;
                        $scope.tunel = data;

                        // Registrar el plugin
                        //Chart.register(ChartDataLabels);

                        infraestructurasService.getInspecciones($scope.tunel.Id)
                            .then(function (response) {
                                var data = response.data;
                                $scope.inspecciones = data;
                                $scope.ultimainspeccion = new Object();
                                $scope.ultimainspeccion.Id = $scope.inspecciones[0].Id;
                                $scope.ultimoInspector = $scope.inspecciones[0].Inspector.Descripcion;
                                $scope.infraestructura.FechaUltimaInspeccion = $scope.inspecciones[0].FechaInicio;

                                // codigo de chatGPT

                                /*let checkInterval = setInterval(function () {*/
                                if (typeof ChartDataLabels !== 'undefined') {
                                    // Si está definido, regístralo y detén el intervalo
                                    Chart.register(ChartDataLabels);
                                    /* console.log('ChartDataLabels registrado correctamente.');*/
                                }
                                        // Cancela el intervalo
                                        ; // Comprueba cada 100 ms

                                        infraestructurasService.getDañosPorTipo($scope.ultimainspeccion.Id)
                                            .then(function (response) {
                                                $scope.dañosportipo = response.data;

                                                // Generar dinámicamente los labels y los datos basados en $scope.daños
                                                const labels = $scope.dañosportipo.map(function (d) {
                                                    return d.Tipo.Descripcion || 'N/A';
                                                });

                                                const data = $scope.dañosportipo.map(function (d) {
                                                    return d.Conteo || 0;
                                                });

                                                // Generar colores dinámicamente si hay más de 3 tipos de daños
                                                const backgroundColors = labels.map(function (_, i) {
                                                    return 'rgba(' +
                                                        Math.floor(Math.random() * 255) + ', ' +
                                                        Math.floor(Math.random() * 255) + ', ' +
                                                        Math.floor(Math.random() * 255) + ', 0.7)';
                                                });

                                                const borderColors = [];
                                                for (var i = 0; i < labels.length; i++) {
                                                    borderColors.push(
                                                        'rgba(' +
                                                        Math.floor(Math.random() * 255) + ', ' +
                                                        Math.floor(Math.random() * 255) + ', ' +
                                                        Math.floor(Math.random() * 255) + ', 1)'
                                                    );
                                                }

                                                // Configuración de los datos del gráfico
                                                const damageData = {
                                                    labels: labels, // Etiquetas dinámicas
                                                    datasets: [{
                                                        label: 'Distribución de Daños',
                                                        data: data, // Datos dinámicos
                                                        backgroundColor: backgroundColors,
                                                        borderColor: borderColors,
                                                        borderWidth: 1
                                                    }]
                                                };

                                                // Configuración del gráfico
                                                const config = {
                                                    type: 'pie',
                                                    data: damageData,
                                                    options: {
                                                        responsive: true,
                                                        plugins: {
                                                            legend: {
                                                                display: false, // Oculta la leyenda
                                                            },
                                                            tooltip: {
                                                                enabled: true, // Mostrar valores al pasar el mouse
                                                            },
                                                            datalabels: {
                                                                color: '#000', // Color del texto
                                                                font: {
                                                                    size: 12, // Tamaño del texto
                                                                    weight: 'bold'
                                                                },
                                                                formatter: function (value, ctx) {
                                                                    // Calcula el porcentaje
                                                                    const total = ctx.chart.data.datasets[0].data.reduce(function (sum, val) {
                                                                        return sum + val;
                                                                    }, 0);
                                                                    const percentage = ((value / total) * 100).toFixed(2);
                                                                    return percentage + '%'; // Devuelve el porcentaje
                                                                }
                                                            }
                                                        },
                                                        layout: {
                                                            padding: 20 // Añade espacio alrededor del gráfico
                                                        }
                                                    }
                                                };

                                                // Creación del gráfico
                                                const ctx = document.getElementById('graficoDaniosPorTipo').getContext('2d');
                                                const damagePieChart = new Chart(ctx, config);
                                            });


                                        infraestructurasService.getDañosPorGravedad($scope.ultimainspeccion.Id)
                                            .then(function (response) {
                                                $scope.dañosporgravedad = response.data;

                                                // Generar dinámicamente los labels y los datos basados en $scope.daños
                                                const labelsGr = $scope.dañosporgravedad.map(function (d) {
                                                    return d.GravedadDaño || 'N/A';
                                                });

                                                const dataGr = $scope.dañosporgravedad.map(function (d) {
                                                    return d.Conteo || 0;
                                                });

                                                // Generar colores dinámicamente si hay más de 3 tipos de daños
                                                //const backgroundColorsGr = labelsGr.map(function (_, i) {
                                                //    return 'rgba(' +
                                                //        Math.floor(Math.random() * 255) + ', ' +
                                                //        Math.floor(Math.random() * 255) + ', ' +
                                                //        Math.floor(Math.random() * 255) + ', 0.7)';
                                                //});

                                                //const borderColorsGr = [];
                                                //for (var i = 0; i < labelsGr.length; i++) {
                                                //    borderColorsGr.push(
                                                //        'rgba(' +
                                                //        Math.floor(Math.random() * 255) + ', ' +
                                                //        Math.floor(Math.random() * 255) + ', ' +
                                                //        Math.floor(Math.random() * 255) + ', 1)'
                                                //    );
                                                //}
                                                const colorMap = {
                                                    'Leve': 'rgba(0, 128, 0, 0.7)',         // Verde
                                                    'Moderado': 'rgba(255, 255, 0, 0.7)',   // Amarillo
                                                    'Severo': 'rgba(255, 165, 0, 0.7)',     // Naranja
                                                    'Critico': 'rgba(255, 0, 0, 0.7)'       // Rojo
                                                };
                                                const darkerColorMap = {
                                                    'Leve': 'rgba(0, 100, 0, 1)',           // Verde oscuro
                                                    'Moderado': 'rgba(200, 200, 0, 1)',     // Amarillo oscuro
                                                    'Severo': 'rgba(200, 100, 0, 1)',       // Naranja oscuro
                                                    'Critico': 'rgba(150, 0, 0, 1)'         // Rojo oscuro
                                                };

                                                // Generar colores de fondo
                                                var backgroundColorsGr = labelsGr.map(function (label) {
                                                    return colorMap[label];
                                                });

                                                // Generar colores de borde
                                                var borderColorsGr = labelsGr.map(function (label) {
                                                    return darkerColorMap[label];
                                                });


                                                // Configuración de los datos del gráfico
                                                const damageDataGr = {
                                                    labels: labelsGr, // Etiquetas dinámicas
                                                    datasets: [{
                                                        label: 'Distribución de Daños',
                                                        data: dataGr, // Datos dinámicos
                                                        backgroundColor: backgroundColorsGr,
                                                        borderColor: borderColorsGr,
                                                        borderWidth: 1
                                                    }]
                                                };

                                                // Configuración del gráfico
                                                const configGr = {
                                                    type: 'pie',
                                                    data: damageDataGr,
                                                    options: {
                                                        responsive: true,
                                                        plugins: {
                                                            legend: {
                                                                display: false, // Oculta la leyenda
                                                            },
                                                            tooltip: {
                                                                enabled: true, // Mostrar valores al pasar el mouse
                                                            },
                                                            datalabels: {
                                                                color: '#000', // Color del texto
                                                                font: {
                                                                    size: 12, // Tamaño del texto
                                                                    weight: 'bold'
                                                                },
                                                                formatter: function (value, ctx) {
                                                                    // Calcula el porcentaje
                                                                    const total = ctx.chart.data.datasets[0].data.reduce(function (sum, val) {
                                                                        return sum + val;
                                                                    }, 0);
                                                                    const percentage = ((value / total) * 100).toFixed(2);
                                                                    return percentage + '%'; // Devuelve el porcentaje
                                                                }
                                                            }
                                                        },
                                                        layout: {
                                                            padding: 20 // Añade espacio alrededor del gráfico
                                                        }
                                                    }
                                                };

                                                // Creación del gráfico
                                                const ctxGr = document.getElementById('graficoDaniosPorGravedad').getContext('2d');
                                                const damagePieChartGr = new Chart(ctxGr, configGr);
                                            });

                                        clearInterval(checkInterval);
                                //    } else {
                                //        console.warn('Esperando a que ChartDataLabels esté disponible...');
                                //    }
                                //}, 100)

                            });

                    });
            });


        $scope.imprimirInforme = function () {
            infraestructurasService.getInforme($scope.ultimainspeccion.Id)
                .then(function (data, status, headers) {

                    var filename = "Informe Inspeccion.doc";                    
                    var contentType = 'application/msword';

                    var blob = new Blob([data.data],
                        { type: contentType });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                        window.navigator.msSaveBlob(blob, filename);
                    else //Resto
                    {
                        var downloadLink = document.createElement("a");
                        var url = window.URL.createObjectURL(blob);
                        downloadLink.setAttribute("target", "_blank");
                        downloadLink.setAttribute("href", url);
                        downloadLink.setAttribute("download", filename);
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                        elemento.style.display = 'none';
                    }
                })
        }


        infraestructurasService.getTiposTunel()
            .then(function (response) {
                var data = response.data;
                $scope.tiposTunel = data;
            });

        infraestructurasService.getObstaculos()
            .then(function (response) {
                var data = response.data;
                $scope.obstaculos = data;
            });

        infraestructurasService.getNumDireccionesTunel()
            .then(function (response) {
                var data = response.data;
                $scope.numDireccionesTunel = data;
            });

        infraestructurasService.getLongitudTunel()
            .then(function (response) {
                var data = response.data;
                $scope.longitudes = data;
            });

        infraestructurasService.getMaterialesTunel()
            .then(function (response) {
                var data = response.data;
                $scope.materiales = data;
            });

        infraestructurasService.getTipoVia()
            .then(function (response) {
                var data = response.data;
                $scope.tiposVia = data;
            });

        infraestructurasService.getCirculacionPermitida()
            .then(function (response) {
                var data = response.data;
                $scope.circulacionesPermitida = data;
            });

        infraestructurasService.getCategoria($rootScope.categoria)
            .then(function (response) {
                var data = response.data;
                $scope.categoria = data;
            });


        $scope.verCategorias = function () {
            $rootScope.inspeccionUrl = false;
            $rootScope.parteUrl = false;
            $location.path('/categorias');
        };



        $scope.guardarInventario = function () {
            $scope.tunel.InfraestructuraId = $routeParams.id;
            $scope.tunel.AñoConstruccion = $scope.tunel.AnioConstruccion;
            $scope.tunel.MaterialHastialDcholId = $scope.tunel.MaterialHastialDchoId;
            if ($scope.tunel.Id != undefined) {
                infraestructurasService.guardarInventario($scope.tunel, $scope.infraestructura.CodIdentificador, $scope.infraestructura.Propietario.Id, $scope.infraestructura.Entorno.Id, $scope.infraestructura.Municipio.Id)
                    .then(function (response) {
                        var data = response.data;
                        $scope.tunel.Id = data;
                    });
                alert("Datos guardados correctamente");
            } else {
                $scope.tunel.Id = -1;
                infraestructurasService.guardarInventario($scope.tunel)
                    .then(function (response) {
                        var data = response.data;
                        $scope.tunel.Id = data;
                    });
                alert("Datos guardados correctamente");
            }
        };


        $scope.guardarInspeccion = function () {
            infraestructurasService.guardarInspeccion($scope.inspeccion)
                .then(function (response) {
                    var data = response.data;
                    $scope.tunel.Id = data;
                });

            alert("Datos guardados correctamente");
        };

        $scope.volver = function () {
            $location.path('/categorias');
        }

        $scope.getModelo = function () {
            //$location.path("/modelo/" + $routeParams.id);
            $scope.OpcionSeleccionada = "app/VisorModelos/visorModelos.html";
            $scope.OpcionSeleccionadaClass = 'modelos';
        }


        $scope.getSensores = function () {
            //$location.path("/modelo/" + $routeParams.id);
            $scope.OpcionSeleccionada = "app/Sensores/Sensores.html";
            $scope.OpcionSeleccionadaClass = 'sensores';
        }

        $scope.nuevaInspeccion = function () {
            $location.url('/inspecciones/0');
        }

        $scope.getNubeDePuntos = function () {
            //$location.path("/TV/" + $routeParams.id);
            $scope.OpcionSeleccionada = "app/TV/TV.html";
            $scope.OpcionSeleccionadaClass = 'tv';
        }

        $scope.getGestionDocumental = function () {
            //$location.path("/TV/" + $routeParams.id);
            $scope.OpcionSeleccionada = "app/GestionDocumental/gestiondocumental.html";
            $scope.OpcionSeleccionadaClass = 'docs';
        }

        $scope.getRealidadVirtual = function () {
            //$location.path("/TV/" + $routeParams.id);
            $scope.OpcionSeleccionada = "app/TourVirtual/tourvirtual.html";
            $scope.OpcionSeleccionadaClass = 'rv';
        }


        $scope.getPartes = function () {
            $scope.OpcionSeleccionada = "app/Partes/partes.html";
            $scope.OpcionSeleccionadaClass = 'parte';
        }

        $scope.getGIS = function () {
            $scope.OpcionSeleccionada = "app/GIS/gis.html";
            $scope.OpcionSeleccionadaClass = 'gis';
        }
        



        $scope.logout = function () {
            //$rootScope.bodylayout = "login";
            return authenticationService.logout();
        }

        $scope.TipologiaCargar = function () {
            $scope.pestanyavisible = 'tipologia';
        }

        $scope.DimensionesCargar = function () {
            $scope.pestanyavisible = 'dimensiones';
        }

        $scope.MaterialesCargar = function () {
            $scope.pestanyavisible = 'materiales';
        }

        $scope.ImportanciaCargar = function () {
            $scope.pestanyavisible = 'importancia';
        }

        $scope.ElementosInspeccionadosCargar = function () {
            $scope.pestanyamenuinspeccion = 'elementosinspeccionados';
        }

        $scope.ElementosEvaluadosCargar = function () {
            $scope.pestanyamenuinspeccion = 'elementosevaluados';
        }

        $scope.InventarioCargar = function () {
            $scope.pestanyamenusuperior = 'inventario';
        }

        $scope.InspeccionCargar = function () {
            $scope.pestanyamenusuperior = 'inspeccion';
        }

        $scope.ConservacionCargar = function () {
            $scope.pestanyamenusuperior = 'conservacion';
        }

        $scope.InformacionCargar = function () {
            //$scope.calcularGrafico();
            $scope.pestanyamenusuperior = 'informacion';
        }

        $scope.VisualizacionCargar = function () {
            $scope.pestanyamenusuperior = 'visualizacion';
        }

    }]);