var infraestructuras = angular.module('chatbox', []);
infraestructuras.controller('chatboxController', ['$scope', '$rootScope', '$location', 'chatboxService', function ($scope, $rootScope, $location, chatboxService) {

    $scope.messages = [];
    $scope.newMessage = "";
    $scope.respuesta = "";

    $scope.messages.push({
        sender: "system",
        text: "Soy Indiana, el asistente de InDi.",
        timestamp: new Date(),
    });
    $scope.messages.push({
        sender: "system",
        text: "¿En que puedo ayudarte?",
        timestamp: new Date(),
    });

    // Estado inicial del chatbox
    $scope.showChatbox = false;
    $scope.showAyuda = true;

    // Lista de mensajes y lógica del chat


    $scope.sendMessage = function () {
        if ($scope.newMessage.trim() !== "") {
            $scope.messages.push({
                sender: "user",
                text: $scope.newMessage,
                timestamp: new Date(),
            });

            chatboxService.getChatBox($scope.newMessage)
                .then(function (response) {
                    $scope.respuesta = response.data.response;
                    if ($scope.respuesta.trim() !== "") {
                        $scope.messages.push({
                            sender: "system",
                            text: $scope.respuesta,
                            timestamp: new Date(),
                        });
                    }
                })
                .catch(function (error) {
                    console.error("Error al obtener respuesta del chatbox:", error);
                });

            $scope.newMessage = "";
        }
    };

    $scope.mostrarChatBox = function () {
        $scope.showChatbox = true;
        $scope.showAyuda = false;
    };

    $scope.mostrarAyuda = function () {
        $scope.showChatbox = false;
        $scope.showAyuda = true;
    };

    // Inicialización automática del chatbox al cargar la aplicación
    $scope.initChatBox = function () {
        $scope.showChatbox = false;
    };

    // Llama a la función de inicialización del chatbox al cargar
    $scope.initChatBox();

}]);
